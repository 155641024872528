<template>
  <div class="login-container">
    <el-form
      class="login-form"
      autoComplete="on"
      :model="loginForm"
      :rules="loginRules"
      ref="loginForm"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">
          Shop Admin
        </h3>
      </div>
      <el-form-item prop="username">
        <el-input
          name="username"
          type="text"
          v-model="loginForm.username"
          autoComplete="on"
          placeholder="请输入用户名"
        />
      </el-form-item>
      <el-form-item prop="password" >
        <el-input
          name="password"
          :type="passwordType"
          @keyup.enter.native="handleLogin"
          v-model="loginForm.password"
          autoComplete="on"
          placeholder="请输入密码"
        />
      </el-form-item>
      <el-button
        type="primary"
        style="width:100%;margin-bottom:30px;"
        :loading="loading"
        @click.native.prevent="handleLogin">登录</el-button>
    </el-form>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import regexps from '@/common/regexps';

export default {
  components: { },
  name: 'login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!regexps.username.test(value)) {
        callback(new Error('请输入正确的用户名'));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码最少为6为位'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: '',
        // username: 'admin',
        // password: 'test111111',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
      },
      passwordType: 'password',
      loading: false,
      showDialog: false,
    };
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch(
            'LoginByUsername',
            this.loginForm,
          ).then(() => {
            this.loading = false;
            this.$router.push({ path: '/' });
          }).catch(() => {
            // Message.error(msg);
            this.loading = false;
          });
        } else {
          Message.error('用户名密码格式不正确');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #eee;
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    max-width: 520px;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
    // border: 1px solid #999;
    background: #fff;
    & .fa-icon {
      width: 18px;
      height: 18px;
    }
  }
  .title-container {
    position: relative;
    & .logo {
      height: 100px;
    }
    .title {
      font-size: 26px;
      font-weight: 400;
      color: #999;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
